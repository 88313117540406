import { render } from "./address.vue?vue&type=template&id=21ba4b10&scoped=true"
import script from "./address.vue?vue&type=script&lang=js"
export * from "./address.vue?vue&type=script&lang=js"

import "./address.vue?vue&type=style&index=0&id=21ba4b10&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-21ba4b10"
/* hot reload */
if (module.hot) {
  script.__hmrId = "21ba4b10"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('21ba4b10', script)) {
    api.reload('21ba4b10', script)
  }
  
  module.hot.accept("./address.vue?vue&type=template&id=21ba4b10&scoped=true", () => {
    api.rerender('21ba4b10', render)
  })

}

script.__file = "src/views/my/address/address.vue"

export default script