<template>
    <div class="address_cont">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    上门地址管理
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='address_content'>
            <div class='address' v-for='(item,index) in addresslist' :key='index'>
                <div class='address_top'>
                    <div class='provinces'>
                        <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default' v-if='item.ISDEFAULT'/>{{item.ADDRESS}}</div>
                        <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit' @click='toedit(item.ID)'/> -->
                        <div class='delete' @click='deleteaddress(item.ID)'>删除</div>
                    </div>
                    <div class='detailed'>
                        {{item.DOORPLATE}}
                    </div>
                </div>
                <!-- <div class='action'>
                    <div class='check_cont'>

                    </div>
                    <div>
                        删除
                    </div>
                </div> -->
            </div>
        </div>


        <div class='add_btn_cont'>
            <van-button type="primary" block round color='#00C291' @click='router.push({name:"addAddress"})'>添加新地址</van-button>
        </div>


        <div class='no_coupon' v-if='!addresslist.length'>
            <img src='https://static.yihu365.cn/img/h5Img/assets/img/no_address.png' class='no_img'/>
            <div>暂无地址</div>
        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import loginApi from '@axios/login'



export default {
  name: 'address',
  components: {

  },
  setup () {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();

    let addresslist = ref([]);

    let getAddress = () => {

        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'selectAddressByUserId',
            _from : 'h5',
            token : store.state.token,
            userid : store.state.uid,
            login_userId_base : store.state.uid,
            _validate : '1',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            console.log(res)
            addresslist.value = res.data;
        })
    }

    let toedit = (id) => {
        router.push({
            name : 'addAddress',
            query : {
                id : id
            }
        })
    }

    const deleteaddress = (id) => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'deleteAddressById',
            ID:id + '',
            _from : 'h5',
            token : store.state.token,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            getAddress();
        })
    }

    getAddress();

    return {
        deleteaddress,
        toedit,
        addresslist,
        router
    }
  },
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .delete{
        color:#F56C6C;
    }
    .address_cont{
        height: 100%;
        background: #f0f0f0;
    }
    .no_coupon{
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #B1AFAF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top:200px;

        img{
            width:300px;
            margin-bottom:25px;
        }
    }
    .address_content{
        padding:24px 30px;
        background: #f0f0f0;
        padding-bottom:150px;
        .address{
            padding:0 15px;
            background:#fff;
            border-radius: 10px;
            margin-bottom:25px;
            .address_top{
                padding-top:40px;
                padding-bottom:25px;
                padding-left:25px;
                // border-bottom: 1px solid #EDEDED;
            }
        }

    }
    .provinces{
        display: flex;
        justify-content: space-between;
        // align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
        padding-right:30px;
        &>div{
            display: flex;
            // align-items: center;
        }
        .default{
            width: 60px;
            height: 30px;
            margin-right:20px;
            position: relative;
            top:5px;
        }
        .icon_edit{
            width: 34px;
            height: 34px;
            position: relative;
            top:5px;
        }
    }
    .detailed{
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        margin-top:18px;
    }
    .action{
        padding:20px;
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .check_cont{
        display: flex;
        align-items: center;
        span{
            margin-left:20px;
        }
    }
    .add_btn_cont{
        width: 690px;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom:16px;
        margin:0 30px;
    }

</style>
